import axios, { AxiosInstance } from "axios";

import { ContentType } from "../types";
import { ServiceDeskFactoryParameters } from "../types/serviceDesk";

export class ChatwootClient {
  ws: WebSocket;
  callback: ServiceDeskFactoryParameters["callback"];

  client: AxiosInstance;
  conversationClient: AxiosInstance | undefined;
  activeConversationId: string | undefined;
  agentId: string | number | undefined;

  constructor(
    baseUrl: string,
    inboxId: string,
    websocketUrl: string,
    callback: ServiceDeskFactoryParameters["callback"]
  ) {
    this.callback = callback;
    this.ws = new WebSocket(`${websocketUrl}/cable`);
    this.client = axios.create({
      baseURL: `${baseUrl}/public/api/v1/inboxes/${inboxId}`,
    });
  }

  private _createContact = async (userId: string) => {
    const { data } = await this.client.post(`/contacts`, { identifier: userId });

    return {
      contactId: data.source_id,
      pubSubToken: data.pubsub_token,
    };
  };

  createConversation = async (userId: string) => {
    const { contactId, pubSubToken } = await this._createContact(userId);

    this.ws.send(JSON.stringify({
      command: "subscribe",
      identifier: JSON.stringify({
        channel: "RoomChannel",
        pubsub_token: pubSubToken,
      }),
    }));
    this.ws.onmessage = this.onMessage;

    const { data } = await this.client.post(`/contacts/${contactId}/conversations`, {
      custom_attributes: {},
    });

    this.conversationClient = axios.create({
      baseURL: `${this.client.defaults.baseURL}/contacts/${contactId}/conversations/${data.id}`,
    });
    this.activeConversationId = data.id;
  };

  sendMessage = async (content: string) => this.conversationClient?.post("/messages", { content });
  setResolved = async () => this.conversationClient?.post("/toggle_status", {});

  onMessage = (event: MessageEvent) => {
    const { message } = JSON.parse(event.data) || {};
    message?.event && console.debug({ event: message?.event, data: message });

    switch (message?.event) {
      case "conversation.created":
        return this.onConversationCreated(message.data);
      case "conversation.updated":
        return this.onConversationUpdated(message.data);
      case "message.created":
        return this.onMessageCreated(message.data);
      case "conversation.status_changed":
        return this.onStatusChanged(message.data);
      case "conversation.typing_on":
        return this.onTyping(message.data)(true);
      case "conversation.typing_off":
        return this.onTyping(message.data)(false);
    }
  };

  onConversationCreated = ({ meta }: any) => {
    const { assignee } = meta;
    this.agentId = !assignee ? 0 : assignee.id;
    this.callback.agentJoined({ id: this.agentId! as string, nickname: this.agentId ? assignee.name : "Agent" });
  };

  onConversationUpdated = ({ meta, status, id }: any) => {
    const { assignee } = meta;

    if (this.activeConversationId !== id || !assignee || !assignee.id || status !== "open") {
      return;
    }

    if (this.agentId === assignee.id) {
      return;
    }

    this.agentId = assignee.id;
    this.callback.agentJoined({ id: this.agentId! as string, nickname: assignee.name });
  };

  getSurveyUrl = (message: string) => {
    const regex = /(https?:\/\/[^\s]+\/survey\/responses\/[^\s]+)/g;
    const [match] = message.match(regex) || [];
    return match;
  };

  onMessageCreated = ({ content, message_type, conversation_id }: any) => {
    if (this.activeConversationId !== conversation_id || message_type === 0) {
      return;
    }

    // Handle CSAT survey messages
    const surveyUrl = this.getSurveyUrl(content);
    if (surveyUrl) {
      this.callback.sendMessageToUser({
        id: [...crypto.getRandomValues(new Uint8Array(16))].map(b => b.toString(16)).join(""),
        output: {
          generic: [{
            response_type: "user_defined",
            user_defined: {
              type: ContentType.Survey,
              surveyUrl: surveyUrl,
            },
          }],
        },
      });
      this.callback.agentTyping(false);
      return;
    }

    this.callback.sendMessageToUser(content);
    this.callback.agentTyping(false);
  };

  onStatusChanged = ({ status, performer, id }: any) => {
    if (this.activeConversationId !== id) {
      return;
    }

    if (status === "resolved" && performer) {
      this.callback.agentEndedChat();
    }
  };

  onTyping = ({ conversation }: any) => (setTyping: boolean) => {
    const { id } = conversation;

    if (this.activeConversationId !== id) {
      return;
    }

    this.callback.agentTyping(setTyping);
  };
}
