import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import { ChatInterface } from "./components/ChatInterface";
import { theme } from "./theme";

export const App = () => {
  const [currentNamespace] = useState<string | undefined>();
  const [config, setConfig] = useState<any>();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get("/config");
      setConfig(data);
    })();
  }, [setConfig]);

  if (!config) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box display="flex" height={1}>
        <CssBaseline />
        <ChatInterface
          key={currentNamespace}
          namespace={currentNamespace}
          integrationID={config.integrationID}
          region={config.region}
          serviceInstanceID={config.serviceInstanceID}
          chatwootBaseUrl={config.chatwootBaseUrl}
          chatwootInboxId={config.chatwootInboxId}
          chatwootWebsocketUrl={config.chatwootWebsocketUrl}
        />
      </Box>
    </ThemeProvider>
  );
};
