import { z } from "zod";

import { contextSchema, userIdSchema } from "./common";

export type RecieveResponse = z.infer<typeof recieveResponseSchema>;
export type RecieveTextResponse = z.infer<typeof recieveTextResponseSchema>;
export type RecieveOptionResponse = z.infer<typeof recieveOptionResponseSchema>;
export type RecieveUserDefinedResponse = z.infer<typeof recieveUserDefinedResponseSchema>;

export type ReceiveData = z.infer<typeof recieveDataSchema>;
export type RecieveEvent = z.infer<typeof recieveEventSchema>;

export const recieveTextResponseSchema = z.strictObject({
  response_type: z.literal("text"),
  text: z.string(),
});

export const recieveUserDefinedResponseSchema = z.strictObject({
  agent_message_type: z.string().optional(),
  response_type: z.literal("user_defined"),
  user_defined: z.any(),
});

export const recieveOptionResponseSchema = z.strictObject({
  response_type: z.literal("option"),
  title: z.string(),
  description: z.string(),
  preference: z.string().optional(),
  options: z.array(
    z.strictObject({
      label: z.string(),
      value: z.strictObject({
        input: z.strictObject({
          text: z.string(),
        }),
      }),
    })
  ),
});

export const recieveResponseSchema = z.discriminatedUnion("response_type", [
  recieveTextResponseSchema,
  recieveUserDefinedResponseSchema,
  recieveOptionResponseSchema,
]);

export type ReceiveEvent = {
  type: "receive";
  data: ReceiveData;
};

export const recieveDataSchema = z.object({
  id: z.string(),
  user_id: userIdSchema,
  output: z.object({
    debug: z.any(),
    history: z.any(),
    intents: z.array(z.any()).optional(),
    entities: z.array(z.any()).optional(),
    generic: z.array(recieveResponseSchema),
  }),
  context: contextSchema.optional(),
});

export const recieveEventSchema = z.object({
  type: z.literal("receive"),
  data: recieveDataSchema,
});
