import { z } from "zod";

import { conversationHistorySchema } from "./conversation";

import { relevancySchema } from ".";

export type ResponseFeedback = z.infer<typeof responseFeedbackSchema>;
export type RelevancyFeedback = z.infer<typeof relevancyFeedbackSchema>;
export type Feedback = z.infer<typeof feedbackSchema>;

export const feedbackBaseSchema = z.strictObject({
  type: z.enum(["relevancy", "feedback"]),
  timestamp: z.string().datetime(),
  userQuestion: z.string().optional(),
  query: z.string().optional(),
});

export const responseFeedbackSchema = feedbackBaseSchema.extend({
  type: z.literal("feedback"),
  conversationHistory: conversationHistorySchema,
  feedback: z.strictObject({
    rating: z.number().int().min(0).max(5),
    text: z.string().optional(),
  }),
});

export const relevancyFeedbackSchema = feedbackBaseSchema.extend({
  type: z.literal("relevancy"),
  feedback: z.strictObject({
    relevancy: relevancySchema,
    url: z.string().url(),
  }),
});

export const feedbackSchema = responseFeedbackSchema.or(relevancyFeedbackSchema);
