// eslint-disable-next-line @typescript-eslint/naming-convention
export const IFrameHelper = {
  isIFrame: () => window.self !== window.top,
  sendMessage: (message: any) => {
    window.parent.postMessage(
      `elx-widget:${JSON.stringify({ ...message })}`,
      "*"
    );
  },
  isValidEvent: (event: any) => {
    const isDataAString = typeof event.data === "string";
    return isDataAString && event.data.indexOf("elx-widget:") === 0;
  },
  getMessage: (event: any) => JSON.parse(event.data.replace("elx-widget:", "")),
};
