import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";

interface ResponseRatingProps {
  onRatingChange?: (rating: number, feedback?: string) => void;
}

export const ResponseRating = ({ onRatingChange }: ResponseRatingProps) => {
  const theme = useTheme();
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState<string>("");
  const [feedbackDialogOpen, setFeedbackDialogOpen] = useState(false);

  const closeDialog = () => {
    setFeedbackDialogOpen(false);
    setRating(null);
  };

  const RatingComponent = () => (
    <Rating
      value={rating}
      onChange={(_, newValue) => {
        setRating(newValue);

        if (feedbackDialogOpen) {
          return;
        }

        if (newValue && newValue > 3) {
          onRatingChange?.(newValue);
          return;
        }

        setFeedbackDialogOpen(true);
        setFeedback("");
      }}
      sx={{
        "& .MuiRating-iconHover": {
          color: theme.palette.info.main,
        },
      }}
    ></Rating>
  );

  return (
    <>
      <RatingComponent />

      <Dialog open={feedbackDialogOpen} onClose={closeDialog} PaperProps={{ sx: { padding: 1 } }} disablePortal={true}>
        <DialogTitle>Thanks for your feedback</DialogTitle>
        <DialogContent>
          <Stack gap={1}>
            <Typography>How can the response be improved?</Typography>
            <RatingComponent />
            <TextField multiline value={feedback} onChange={(event) => setFeedback(event.target.value)} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            color="warning"
            onClick={() => {
              setFeedbackDialogOpen(false);
              setRating(null);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!feedback && rating !== 5}
            onClick={() => {
              setFeedbackDialogOpen(false);

              if (rating === null || rating === undefined) {
                return;
              }

              onRatingChange?.(rating, feedback);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
