import ReactDOM from "react-dom/client";

import "@fontsource/ibm-plex-sans/300.css";
import "@fontsource/ibm-plex-sans/400.css";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/700.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./index.css";

import { App } from "./App.tsx";
import { IFrameHelper } from "./utils/iframe.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);

if (IFrameHelper.isIFrame()) {
  window.addEventListener("message", (event) => {
    if (!IFrameHelper.isValidEvent(event)) {
      return;
    }

    const data = IFrameHelper.getMessage(event);
    switch (data.event) {
      case "load-css": {
        console.log("blocking css");
        const style = document.createElement("style");
        style.innerHTML = data.css;
        document.body.appendChild(style);
        break;
      }
    }
  });
}
