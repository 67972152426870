import { z } from "zod";

import { MessageType } from "./common";
import {
  contextSchema,
  recieveOptionResponseSchema,
  recieveTextResponseSchema,
  recieveUserDefinedResponseSchema,
  sendMessageSchema,
} from "./watson";

export type Message = z.infer<typeof messageSchema>;

export type UserMessage = z.infer<typeof userMessageSchema>;
export type BotMessage = z.infer<typeof botMessageSchema>;
export type BotTextMessage = z.infer<typeof botTextMessageSchema>;
export type BotOptionMessage = z.infer<typeof botOptionMessageSchema>;
export type BotUserDefinedMessage = z.infer<typeof botUserDefinedMessageSchema>;

export type ConversationHistory = z.infer<typeof conversationHistorySchema>;

export const userMessageSchema = sendMessageSchema.extend({ ts: z.number(), type: z.literal(MessageType.User) });

export const botTextMessageSchema = recieveTextResponseSchema.extend({
  ts: z.number(),
  type: z.literal(MessageType.Bot),
});
export const botOptionMessageSchema = recieveOptionResponseSchema.extend({
  ts: z.number(),
  type: z.literal(MessageType.Bot),
});
export const botUserDefinedMessageSchema = recieveUserDefinedResponseSchema.extend({
  ts: z.number(),
  type: z.literal(MessageType.Bot),
});

export const botMessageSchema = z.union([
  botTextMessageSchema,
  botOptionMessageSchema,
  botUserDefinedMessageSchema,
]);

export const messageSchema = z.union([botMessageSchema, userMessageSchema]);

export const conversationHistorySchema = z.strictObject({
  context: contextSchema.optional(),
  messages: z.array(messageSchema),
});
