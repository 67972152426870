import { ThumbDownOutlined, ThumbUpOutlined } from "@mui/icons-material";
import { Card, CardActionArea, CardActions, CardContent, IconButton, Theme, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import { LinkCard, Relevancy } from "../types";

interface ContentCardProps {
  card: LinkCard;
  onRelevancyChange?: (rating: Relevancy) => void;
}

const getRatingColor = (theme: Theme, rating?: Relevancy): string | undefined => {
  if (rating === Relevancy.Relevant) {
    return theme.palette.success.main;
  }

  if (rating === Relevancy.Irrelevant) {
    return theme.palette.error.main;
  }
};

export const ContentCard = ({ card: { title, url, text: description }, onRelevancyChange }: ContentCardProps) => {
  const [relevancy, setRelevancy] = useState<Relevancy | undefined>();
  const theme = useTheme();

  const _onRelevancyChange = (relevancy: Relevancy) => {
    setRelevancy(relevancy);
    onRelevancyChange?.(relevancy);
  };

  return (
    <Card>
      <CardActionArea href={url} target="_blank">
        <CardContent sx={{ height: 120, display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography
            variant="body1"
            fontWeight="bold"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <IconButton
          onClick={() => _onRelevancyChange(Relevancy.Relevant)}
          sx={{
            color: relevancy === Relevancy.Relevant ? getRatingColor(theme, Relevancy.Relevant) : undefined,
            ":hover": { color: getRatingColor(theme, Relevancy.Relevant) },
          }}
        >
          <ThumbUpOutlined />
        </IconButton>
        <IconButton
          onClick={() => _onRelevancyChange(Relevancy.Irrelevant)}
          sx={{
            color: relevancy === Relevancy.Irrelevant ? getRatingColor(theme, Relevancy.Irrelevant) : undefined,
            ":hover": { color: getRatingColor(theme, Relevancy.Irrelevant) },
          }}
        >
          <ThumbDownOutlined />
        </IconButton>
      </CardActions>
    </Card>
  );
};
