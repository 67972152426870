import { z } from "zod";

export const userIdSchema = z.string().min(1).max(256).optional();

const browserInfoSchema = z.strictObject({
  browser_name: z.string(),
  browser_version: z.string(),
  browser_OS: z.string(),
  language: z.string(),
  page_url: z.string().url(),
  screen_resolution: z.string(),
  user_agent: z.string(),
  client_ip_address: z.string().ip(),
  // should ideally be validated as .string().ip() but currently these sometimes have spaces at the start/end
  ip_address_list: z.array(z.string()),
});

// Not strict, context and integrations have some additional fields we're ignoring
export const contextSchema = z.object({
  global: z.strictObject({
    system: z.strictObject({
      session_start_time: z.string().datetime(),
      user_id: userIdSchema,
      timezone: z.string(),
      turn_count: z.number().int().min(0),
      state: z.string(),
    }),
    session_id: z.string(),
  }),
  integrations: z.object({
    chat: z.strictObject({
      browser_info: browserInfoSchema,
    }),
  }),
});

export type Context = z.infer<typeof contextSchema>;
