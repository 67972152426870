import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    info: {
      main: "#FAA61A",
    },
  },
  typography: {
    fontFamily: "IBM Plex Sans, sans-serif",
  },
});
