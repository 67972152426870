import { z } from "zod";

import { contextSchema } from "./common";

export type SendMessage = z.infer<typeof sendMessageSchema>;
export type SendEvent = z.infer<typeof sendEventSchema>;

export const sendMessageSchema = z.strictObject({
  text: z.string().min(1).max(2048),
  message_type: z.enum(["text", "search"]),
  options: z
    .object({
      export: z.boolean(),
      debug: z.boolean(),
      return_context: z.boolean(),
      alternate_responses: z.boolean(),
      disambiguation: z.strictObject({
        alternate_responses: z.boolean(),
      }),
    })
    .partial()
    .optional(),
});

export const sendEventSchema = z.strictObject({
  type: z.literal("send"),
  data: z.strictObject({
    id: z.string(),
    input: sendMessageSchema,
    context: contextSchema.optional(),
  }),
});
