import { Box, Stack, Typography } from "@mui/material";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ContentCard } from "./ContentCard";
import { ResponseRating } from "./ResponseRating";

import { sendFeedback } from "../api/logs";
import { ConversationHistory, LinkCard, Relevancy, RelevancyFeedback, ResponseFeedback } from "../types";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

interface ContentCarouselProps {
  cards?: LinkCard[];
  conversationHistory: ConversationHistory;
  userQuestion?: string;
  query?: string;
}

export const ContentCarousel = ({ cards = [], conversationHistory, userQuestion, query }: ContentCarouselProps) => {
  const onRatingChange = (rating: number, feedback?: string) => {
    const timestamp = new Date().toISOString();
    const responseFeedback: ResponseFeedback = {
      type: "feedback",
      timestamp,
      userQuestion,
      conversationHistory,
      query,
      feedback: {
        rating,
        text: feedback,
      },
    };

    sendFeedback(responseFeedback);
  };

  const onRelevancyChange = (relevancy: Relevancy, url: string) => {
    const timestamp = new Date().toISOString();
    const feedback: RelevancyFeedback = {
      type: "relevancy",
      timestamp,
      userQuestion,
      query,
      feedback: {
        relevancy,
        url,
      },
    };

    sendFeedback(feedback);
  };

  if (!cards?.length) {
    return null;
  }

  return (
    <Box width={1} my={2}>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/swiper@10/swiper-bundle.min.css"
      />
      <Swiper
        modules={[Pagination]}
        spaceBetween={20}
        slidesPerView="auto"
        pagination={{ clickable: true }}
      >
        {cards?.map((card, index) => (
          <SwiperSlide key={index} style={{ width: 300, paddingBottom: 50 }}>
            <ContentCard card={card} onRelevancyChange={(relevancy) => onRelevancyChange(relevancy, card.url)} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Stack direction="row" justifyContent="end" gap={1} mt={2}>
        <Typography>Rate the response:</Typography>
        <ResponseRating onRatingChange={onRatingChange} />
      </Stack>
    </Box>
  );
};
