import { WebChatConfig, WebChatContainer, WebChatInstance } from "@ibm-watson/assistant-web-chat-react";
import { UserDefinedResponseEvent } from "@ibm-watson/assistant-web-chat-react/dist/types/UserDefinedResponseEvent";
import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import { ContentCarousel } from "./ContentCarousel";
import { IsolatedComponent } from "./IsolatedComponent";

import { ChatwootServiceDeskFactory } from "../chatwoot/serviceDesk";
import { useWatsonLogger } from "../hooks/logger";
import {
  ContentType,
  ConversationHistory,
  Message,
  RecieveTextResponse,
  recieveDataSchema,
  recieveResponseSchema,
  recieveUserDefinedResponseSchema,
} from "../types";
import { findLastUserMessage } from "../utils/analytics";
import { IFrameHelper } from "../utils/iframe";

interface ChatInterfaceProps {
  integrationID: WebChatConfig["integrationID"];
  region: WebChatConfig["region"];
  serviceInstanceID: WebChatConfig["serviceInstanceID"];
  namespace?: string;
  chatwootBaseUrl: string;
  chatwootWebsocketUrl: string;
  chatwootInboxId: string;
}

const renderUserDefinedResponse = (conversationHistory: ConversationHistory) => (event: UserDefinedResponseEvent) => {
  const responseMessage = recieveResponseSchema.parse(event.data.message);
  const responseData = recieveDataSchema.parse(event.data.fullMessage);

  const response = recieveUserDefinedResponseSchema.safeParse(responseMessage);
  if (!response.success) {
    return null;
  }

  const { user_defined: content } = response.data;
  switch (content.type) {
    case ContentType.Survey:
      return (
        <IsolatedComponent>
          <iframe src={content?.surveyUrl}
            width="100%"
            style={{
              border: 0,
              colorScheme: "normal",
              height: "100% !important",
              width: "100% !important",
            }} />
        </IsolatedComponent>
      );
    case ContentType.Carousel: {
      const textOutput = responseData.output.generic.find(
        (output) => output.response_type === "text"
      ) as RecieveTextResponse;

      const lastUserMessage = textOutput && findLastUserMessage(conversationHistory.messages, textOutput.text)?.text;

      return (
        <IsolatedComponent>
          <ContentCarousel
            cards={content.cards}
            conversationHistory={conversationHistory}
            userQuestion={lastUserMessage}
            query={content.searchQuery}
          />
        </IsolatedComponent>
      );
    }
    default:
      return null;
  }
};

export const ChatInterface = ({
  namespace,
  integrationID,
  region,
  serviceInstanceID,
  chatwootBaseUrl,
  chatwootWebsocketUrl,
  chatwootInboxId,
}: ChatInterfaceProps) => {
  const chatRef = useRef<HTMLElement>();
  const conversationRef = useRef<Message[]>([]);
  const [webChatConfig, setWebChatConfig] = useState<WebChatConfig>();
  const {
    onReceive,
    onSend,
    onHistoryEnd,
    onRestartConversation,
    conversationContext,
    conversationHistory,
  } = useWatsonLogger();

  useEffect(() => {
    conversationRef.current = conversationHistory;
  }, [conversationHistory]);

  useEffect(() => {
    if (!chatRef.current) {
      return;
    }

    setWebChatConfig({
      openChatByDefault: true,
      showLauncher: false,
      disableCustomElementMobileEnhancements: true,
      headerConfig: {
        minimizeButtonIconType: "close",
        hideMinimizeButton: !IFrameHelper.isIFrame(),
        showRestartButton: true,
      },
      themeConfig: {
        corners: "square",
        carbonTheme: "g10",
      },
      layout: {
        showFrame: false,
        hasContentMaxWidth: true,
      },
      integrationID,
      region,
      serviceInstanceID,
      namespace,
      element: chatRef.current,
      serviceDeskFactory: (params: any) =>
        new ChatwootServiceDeskFactory(
          params, chatwootBaseUrl, chatwootInboxId, chatwootWebsocketUrl, conversationRef),
      servers: {
        assistantURLPrefix: `/watson/${region}/assistant`,
        webChatScriptPrefix: `/watson/${region}/webchat`,
      },
    });
  }, [
    integrationID,
    region,
    serviceInstanceID,
    namespace,
    chatwootBaseUrl,
    chatwootInboxId,
    chatwootWebsocketUrl,
    conversationRef,
  ]);

  const onAfterRender = async (instance: WebChatInstance) => {
    instance.on({ type: "receive", handler: onReceive });
    instance.on({ type: "send", handler: onSend });
    instance.on({ type: "history:end", handler: onHistoryEnd });
    instance.on({ type: "restartConversation", handler: onRestartConversation });

    instance.on({
      type: "view:change", handler: ({ reason }: any) => {
        if (IFrameHelper.isIFrame() && reason === "mainWindowMinimized") {
          IFrameHelper.sendMessage({ event: "closeWindow" });
        }
      },
    });

    window.addEventListener("message", (event) => {
      if (!IFrameHelper.isValidEvent(event)) {
        return;
      }

      const data = IFrameHelper.getMessage(event);
      if (data.event === "toggle-open" && data.isOpen) {
        instance.changeView("mainWindow");
      }
    });
  };

  const history: ConversationHistory = { context: conversationContext, messages: conversationHistory };

  return (
    <>
      <Box ref={chatRef} height={1} width={1} />
      {webChatConfig && (
        <WebChatContainer
          config={webChatConfig}
          renderUserDefinedResponse={renderUserDefinedResponse(history)}
          onAfterRender={onAfterRender}
        />
      )}
    </>
  );
};
